define("discourse/plugins/discourse-policy/discourse/components/modal/policy-builder", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/object", "@ember/utils", "@ember-compat/tracked-built-ins", "discourse/components/d-button", "discourse/components/d-modal", "discourse/lib/ajax", "discourse/lib/text", "discourse-common/helpers/i18n", "discourse/plugins/discourse-policy/discourse/components/policy-builder-form", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _helper, _object, _utils, _trackedBuiltIns, _dButton, _dModal, _ajax, _text, _i18n, _policyBuilderForm, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _PolicyBuilder;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let PolicyBuilder = _exports.default = (_class = (_PolicyBuilder = class PolicyBuilder extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "isSaving", _descriptor, this);
      _initializerDefineProperty(this, "flash", _descriptor2, this);
      _defineProperty(this, "policy", this.args.model.policy || new _trackedBuiltIns.TrackedObject({
        reminder: "daily",
        version: 1
      }));
    }
    insertPolicy() {
      if (!this.validateForm()) {
        return;
      }
      this.args.model.toolbarEvent?.addText("\n\n" + `[policy ${this.markdownParams}]\n${(0, _i18n.default)("discourse_policy.accept_policy_template")}\n[/policy]` + "\n\n");
      this.args.closeModal();
    }
    async updatePolicy() {
      if (!this.validateForm()) {
        return;
      }
      this.isSaving = true;
      try {
        const result1 = await (0, _ajax.ajax)(`/posts/${this.args.model.post.id}`);
        const newRaw1 = this.replaceRaw(result1.raw);
        if (newRaw1) {
          this.args.model.post.save({
            raw: newRaw1,
            cooked: (await (0, _text.cook)(result1.raw)).toString(),
            edit_reason: (0, _i18n.default)("discourse_policy.edit_reason")
          });
        }
      } finally {
        this.isSaving = false;
        this.args.closeModal();
      }
    }
    get markdownParams() {
      const markdownParams1 = [];
      for (const [key1, value1] of Object.entries(this.policy)) {
        if ((0, _utils.isPresent)(value1)) {
          markdownParams1.push(`${key1}="${value1}"`);
        }
      }
      return markdownParams1.join(" ");
    }
    replaceRaw(raw1) {
      const policyRegex1 = new RegExp(`\\[policy\\s(.*?)\\]`, "m");
      const policyMatches1 = raw1.match(policyRegex1);
      if (policyMatches1?.[1]) {
        return raw1.replace(policyRegex1, `[policy ${this.markdownParams}]`);
      }
      return false;
    }
    validateForm() {
      if ((0, _utils.isBlank)(this.policy.groups)) {
        this.flash = (0, _i18n.default)("discourse_policy.builder.errors.group");
        return false;
      }
      if ((0, _utils.isBlank)(this.policy.version)) {
        this.flash = (0, _i18n.default)("discourse_policy.builder.errors.version");
        return false;
      }
      return true;
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <DModal
        @title={{i18n "discourse_policy.builder.title"}}
        @closeModal={{@closeModal}}
        @flash={{this.flash}}
        @flashType="error"
        class="policy-builder"
      >
        <:body>
          <PolicyBuilderForm
            @policy={{this.policy}}
            @onChange={{fn set this.policy}}
          />
        </:body>
  
        <:footer>
          {{#if @model.insertMode}}
            <DButton
              @label="discourse_policy.builder.insert"
              @action={{this.insertPolicy}}
              class="btn-primary"
            />
          {{else}}
            <DButton
              @label="discourse_policy.builder.save"
              @action={{this.updatePolicy}}
              @isLoading={{this.isSaving}}
              class="btn-primary"
            />
          {{/if}}
        </:footer>
      </DModal>
    
  */
  {
    "id": "RlsHX5Wh",
    "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"policy-builder\"]],[[\"@title\",\"@closeModal\",\"@flash\",\"@flashType\"],[[28,[32,1],[\"discourse_policy.builder.title\"],null],[30,1],[30,0,[\"flash\"]],\"error\"]],[[\"body\",\"footer\"],[[[[1,\"\\n        \"],[8,[32,2],null,[[\"@policy\",\"@onChange\"],[[30,0,[\"policy\"]],[28,[32,3],[[32,4],[30,0,[\"policy\"]]],null]]],null],[1,\"\\n      \"]],[]],[[[1,\"\\n\"],[41,[30,2,[\"insertMode\"]],[[[1,\"          \"],[8,[32,5],[[24,0,\"btn-primary\"]],[[\"@label\",\"@action\"],[\"discourse_policy.builder.insert\",[30,0,[\"insertPolicy\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"          \"],[8,[32,5],[[24,0,\"btn-primary\"]],[[\"@label\",\"@action\",\"@isLoading\"],[\"discourse_policy.builder.save\",[30,0,[\"updatePolicy\"]],[30,0,[\"isSaving\"]]]],null],[1,\"\\n\"]],[]]],[1,\"      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\",\"@model\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-policy/discourse/components/modal/policy-builder.js",
    "scope": () => [_dModal.default, _i18n.default, _policyBuilderForm.default, _helper.fn, _object.set, _dButton.default],
    "isStrictMode": true
  }), _PolicyBuilder), _PolicyBuilder), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isSaving", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "flash", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "insertPolicy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "insertPolicy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatePolicy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updatePolicy"), _class.prototype)), _class);
});